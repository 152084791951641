:root {
  --primary: #86bc25;
  --button: #26890D;
  --dark-green: #2C5234;
  --darkest-green: #1D2411;
  --background: rgb(15, 15, 12);
  --light: rgb(255, 255, 255);
  --light-gray: rgba(255, 255, 255, .6);
  --gray: #CBCBCB;
}

@import 'fonts';

* {
  box-sizing: border-box;
}

html {
  height: 100vh;
  overflow-x: hidden;
  scroll-padding-top: 70px;
  font-size: 18px;
  scroll-behavior: smooth;
}

body {
  background: var(--background);
  color: #fff;
  padding: 0;
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  line-height: 1.35;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--primary);

  &:first-child {
    margin-top: 0;
  }
}

h1 {
  font-size: 2.2em;
}

h2 {
  font-size: 1.66em;
}

a {
  color: inherit;
  text-decoration: inherit;
}

section {
  padding: 6rem 0;
  position: relative;
}

input {
  padding: .5em;
  font-size: 1rem;
}

img {
  display: block;
  max-width: 100%;
}

button,
.button {
  font-size: .77em;
  background-color: var(--button);
  display: inline-block;
  padding: .7em 2em;
  transition: .2s;

  &:hover {
    background-color: var(--primary);
    color: var(--background);
  }
}

.buttons {
  display: flex;
  margin-top: 4rem;

  .button {
    display: block;
    margin-right: 1em;
  }
}

p {
  font-size: 1em;
}

.block-with-image {
  display: flex;
  margin: 0 0 6rem;

  img {
    flex: 0 0 3rem;
    object-fit: cover;
    width: 4rem;
    background-color: #aaa;

    &:first-child {
      margin-right: 2.33rem;
    }

    &:last-child {
      margin-left: 2.33rem;
    }
  }

  h3 {
    color: inherit;
    font-size: 1.33em;
    font-weight: 400;
  }

  .content {
    padding: .5em 0;
  }

  *:first-child {
    margin-top: 0;
  }

  *:last-child {
    margin-bottom: 0;
  }

  &:last-of-type {
    margin-bottom: 3rem;
  }
}

#nav-wheel {
  visibility: hidden;
  position: fixed;
  top: 50%;
  transform: translate(-60%, -50%);

  .wheel {
    width: 21.6vw;
    height: 21.6vw;

    img {
      width: 100%;
      height: 100%;
      display: block;
    }
  }

  ul {
    list-style: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0;
    margin: 0;

    li {
      position: absolute;
      display: block;
      white-space: nowrap;

      a {
        font-size: 1.2em;
        color: var(--light-gray);
        transition: color .3s;

        strong {
          transition: inherit;
        }

        span {
          transition: scale .5s, opacity .5s;
          display: inline-block;
        }

        &:hover,
        &.active {
          color: var(--light);

          strong {
            color: var(--primary);
          }
        }
      }

      &.hide span {
        transform: scaleX(0);
        opacity: 0;
        width: 0;
      }
    }
  }
}

.scroll-indicator {
  display: block;
  position: fixed;
  bottom: 3rem;
  width: 100%;
  left: 0;
  right: 0;
  text-align: center;

  p { margin-bottom: 1em !important;}

  img {
    margin: auto;
    animation: .7s bounce-scroll infinite alternate ease-in;
  }
}

header {
  position: fixed;
  background-color: #000;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;

  .header-container {
    width: 90vw;
    max-width: 1200px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    height: 70px;
    align-items: center;
  }

  .logo {
    img {
      width: 130px;
      margin-right: 20px;
      display: inline-block;
    }

    span {
      border-left: 1px solid var(--light-gray);
      padding-left: 20px;
      display: inline-block;
      color: var(--primary);
      font-size: 1.66em;
      font-weight: 700;
    }
  }

  nav ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;

    li {
      display: block;
      margin-left: 2em;
    }
  }
}

#hero {
  min-height: 400vh;
  display: flex;
  align-items: flex-start;

  h1 {
    font-weight: 400;
    color: var(--light);

    strong {
      font-size: 5rem;
    }
  }

  .captions {
    position: fixed;
    top: 50%;
    transform: translateY(-10vh);
    text-align: center;
    width: 100%;
    left: 0;
    right: 0;

    p {
      position: absolute;
      top: 0;
      opacity: 0;
      font-size: 10vh;
      font-weight: 600;
      margin: 0;
      line-height: 1;
      width: 100%;
      left: 0;
      right: 0;
    }
  }

  .canvas-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    max-height: 100vh;
    pointer-events: none;
  }

  canvas {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: cover;

    &.hidden {
      display: none;
    }
  }

  .section-content {
    font-size: 1.1em;
    text-align: center;
    margin: 40vh auto 0;
    max-width: 35em;
    transform: translateY(-50%);
  }
}

.topics-wrapper {
  padding: 0;

  section {
    margin-left: calc(12vw + 13em);
  }

  .section-content {
    position: relative;
    margin: auto;
    max-width: 40em;

    >h1,
    >h2,
    >h3 {
      margin: 0 0 3rem;
    }
  }

}

#short-intro {
  display: flex;
  min-height: 150vh;
  padding: 0;
  font-size: 3em;
  font-weight: 800;
  line-height: 2;
  letter-spacing: .01em;

  .section-content {
    position: absolute;
    opacity: 0;
    transition: opacity .3s;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }

  p {
    width: fit-content;
    margin: auto;
  }

  .shown {
    position: fixed;
    opacity: 1;
  }

  span { 
    opacity: .3;
    transition: opacity .1s;
  
    &.highlight { opacity: 1;}
  }

  strong {
    font-weight: 700;
  }
}

#choose-place {
  text-align: center;
  margin-left: 0;
  z-index: 1;
  pointer-events: none;
  height: 120vh;
  
  > div {
    font-size: 3em;
    font-weight: 800;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
  }
}

.about-wrapper {
  width: 100vw;
}


#about {
  padding: 0;
  display: flex;

  .slide {
    flex: 0 0 auto;
    height: calc(100vh - 70px);
    display: flex;
    margin: auto 0;
    padding: 5vh 0;

    &__header {
      min-height: 12rem;
    }

    &__body {
      margin: auto 0;
    }

    p {
      font-size: 1.33rem;
    }

  }

  .wrapper {
    padding: 0 5vw;
    display: flex;
    flex-direction: column;
  }

  .title {
    line-height: 1;
    font-size: 3.5rem;
    margin-bottom: 0;
  }

  &-cover {
    min-width: 100vw;

    .wrapper {
      width: 100%;
      margin: auto;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .logo {
        width: 320px;
        margin-right: 8rem;
      }

      .cover-text {
        width: 500px;
      }
    }
  }

  &-description {
    width: 80vw;

    .wrapper {
      width: 100%;
    }

    p {
      max-width: 30em;
      display: block;
    }

    .left-text,
    .right-text {
      position: relative;
      width: 100%;
    }

    .right-text p {
      margin-left: auto;
    }
  }

  &-process {
    padding: 0;
    width: unset;
    display: flex;
    align-items: center;

    .step {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 22rem;
      margin-right: 2em;
      background-color: var(--dark-green);
      transition: background-color .5s, color .5s;

      h3 {
        font-size: 2em;
        margin-top: 0;
      }

      ul {
        padding: 0 0 0 1em;
        margin: 0;
      }

      .text {
        z-index: 1;
        padding: 2.5em 2.5em 1em;
        margin: auto 0;

        p:first-child {
          margin: 0;
        }
      }

      figure {
        text-align: center;
        display: block;
        margin: 0 0 1em;
        width: 100%;
        padding: 0 1em;
        z-index: 1;
      }

      figcaption {
        color: var(--gray);
      }

      img {
        width: 100%;
        margin: 0 auto;
      }

      .background {
        z-index: 0;

        img {
          width: 100%;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: auto;
        }

        span {
          color: #00000033;
          position: absolute;
          right: 0;
          top: 0;
          overflow: hidden;
          font-size: 8rem;
          font-weight: 800;
          line-height: .8;
        }
      }

      &:nth-child(even) {
        transform: translateY(-100px);
      }

      &.active {
        background-color: var(--light);
        color: var(--background);

        figcaption {
          color: rgb(112, 112, 112);
        }
      }
    }
  }

  &-whyCampfire {
    p {
      max-width: 30em;
      margin-bottom: 2em;
      transform: translate(0);
    }
  }

  &-randomToOrchestrated {
    .wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &-howWeDoIt {
    min-width: 60vw;

    .combine {
      display: flex;
      justify-content: center;

      ul {
        position: relative;
        z-index: 2;
        list-style-type: none;
        padding: 0;
        line-height: 1.5;
        text-align: center;
        font-weight: 700;
        opacity: 0;
        transform: translateY(-20px);
        transition: opacity .2s, transform .2s;
        transition-delay: 0;
      }

      .business,
      .tech {
        background-color: var(--darkest-green);
        width: 22rem;
        height: 22rem;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        overflow: hidden;
        mix-blend-mode: screen;
      }

      .tech {
        margin-left: -3em;
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        z-index: 1;
        transition: opacity .6s;
      }

      &.active,
      &:hover {
        img {
          opacity: .33;
        }

        ul {
          transform: translateY(0);
          opacity: 1;
          transition-delay: .4s;
        }
      }
    }
  }

  &-help {
    width: 90vw;
    position: relative;

    .slide__header {
      z-index: 1;
      pointer-events: none;
    }

    .startingpoints {
      position: absolute;
      right: 5vw;
      top: 0;
      bottom: 0;
      width: max(60vw, 700px);
      font-size: 1rem;
      &::before{
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        opacity: .2;
        background-image: url(../assets/images/nodeBall.jpg);
        background-color: var(--background);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50%;
        background-blend-mode: lighten;
      }

      &__item {
        position: absolute;
        transition: opacity .3s;
        width: 18rem;
        text-align: center;
        cursor: pointer;

        .intro {
          font-weight: 700;
          margin-bottom: 1em;

          span {
            transform: translateY(-100%);
            opacity: 0;
            transition: .3s;
            display: block;
          }
        }

        .description {
          transform: translateY(-100%);
          opacity: 0;
          transition: .3s;
        }

        p {
          font-size: inherit;
        }
      }

      &.has-shown-items .startingpoints__item {
        opacity: .2;

        &.active {
          opacity: 1;
          cursor: default;

          .description {
            transform: translateY(0);
            opacity: 1;
          }

          .intro span {
            transform: translateY(0);
            opacity: 1;
          }
        }

        &.active .circle {
          box-shadow: 0 0 15px 2px var(--primary);
        }
      }

      #monetize { top: 20%; left: 30%; }
      #distribute { top: 20%; left: unset; right: 5%; }
      #connect { top: 40%; left: 45%; }
      #data-catalog { top: unset; bottom: 5%; left: 30%; }
      #transparency { top: unset; bottom: 20%;left: unset; right: 0 }
    }

    .circle {
      background-color: var(--light);
      display: flex;
      align-items: center;
      justify-content: center;
      width: 3.5em;
      height: 3.5em;
      border-radius: 50%;
      margin: 0 auto .5rem;
      transition: .3s;
      padding: .7em;

      img {
        display: block;
        max-height: 100%;
        margin: auto;
      }
    }
  }

  &-useCases {
    background-color: var(--light);
    color: var(--background);
    display: grid;
    grid-template-columns: repeat(4, minmax(min-content, 20vw));
    grid-template-rows: min-content 2em auto;
    padding: 0;

    .usecases__head {
      grid-column: 1 /span 4;
      padding: 2em;
    }

    .usecases__inside,
    .usecases__outside {
      grid-row: 2;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .usecases__inside {
      background-color: #2C523466;
    }

    .usecases__outside {
      background-color: #86BC2566;
      grid-column: 2 / span 3;
    }

    .usecases__card {
      grid-row: 3;
      padding: 1em 2em;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      h3 {
        min-height: 3em;
        color: var(--background);
        margin: 0 0 1.5rem;
      }

      img {
        height: 100px;
        margin-bottom: 1.5rem;
      }

      .next-level {
        margin-top: auto;
        padding: 2em 0;
      }
    }
  }

  &-covering {
    width: 100vw;

    .slide__header {
      width: 100%;
      max-width: 35em;
    }

    .slide__body {
      position: absolute;
      top: 0;
      left: 5vw;
      bottom: 0;
      right: 0;
      align-items: center;
      display: flex;

      .descriptions {
        flex: 0 1 auto;
        overflow: hidden;

        >div {
          transition: transform .3s;
          max-height: 0;
          max-width: 22rem;
          overflow: hidden;
          transform: translateX(-100%);

          h3 {
            font-size: 1em;
            border-bottom: 1px solid currentColor;
          }
        }
      }
    }

    svg {
      flex: 0 1 auto;
      display: block;
      height: 70vh;
      width: auto;
      margin: auto;

      circle {
        transition: fill .1s;
      }

      #circle-software-solution {
        fill: #575757;

        &:hover,
        &.active {
          fill: #86BC25;
        }
      }

      #circle-product-poc {
        fill: #484848;

        &:hover,
        &.active {
          fill: #63AC41;
        }
      }

      #circle-product-mvp {
        fill: #383838;

        &:hover,
        &.active {
          fill: #468629;
        }
      }

      #circle-sellable {
        fill: #272727;

        &:hover,
        &.active {
          fill: #2D683D;
        }
      }

      #circle-marketable {
        fill: #141414;

        &:hover,
        &.active {
          fill: #3A818E;
        }
      }
    }
  }
}

#contact {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .section-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: flex-start;
    padding: 0 10vw;
  }

  .contact__options {
    margin: auto 0;
    width: 50%;
  }

  .topic-links {    
    &__wrapper {
      display: flex;
    }

    &__item {
      display: flex;
      flex-direction: column;
      margin-right: 2em;
      flex: 0 1 300px;
      

      img {
        display: block;
        width: 100%;
        margin-bottom: 1rem;
      }

      a {
        font-weight: 700;
        transition: color .3s;
      }

      &:hover {
        a { color: var(--primary); }
      }
    }
  }
}

footer {
  height: 3em;
  ul {
    display: flex;
    list-style-type: none;
    justify-content: center;
    align-items: center;

    li {
      margin: 0 1em;
    }
  }
}

@media screen and (max-width: 1440px),
screen and (max-height: 800px) {
  html {
    font-size: 14px;
  }

  #about{
    .title {
      font-size: 2.5em;
    }

    &-process .step {
      width: 18rem;
  
      .text {
        padding: 1.5em 2em;
      }
    }

    &-help .startingpoints{
      top: 12rem;
  
      #monetize { top: 15%; left: 20%; }
      #distribute { top: 15%; left: unset; right: 10%; }
      #connect { top: 30%; left: 40%; }
      #data-catalog { top: unset; bottom: 5%; left: 30%; }
      #transparency { top: unset; bottom: 10%;left: unset; right: 5% }  
    }
  }

}

@media (max-width: 1024px) {
  #nav-wheel {
    display: none;
  }

  .topics-wrapper {
    section { margin: 0 auto; }
    .section-content { width: 90vw; }
  }
}

@media (max-width: 767px) {
  .block-with-image { 
    flex-direction: column;
    margin-bottom: 3rem;

    img {
      order: -1;
      flex: 0 1 5rem;
      width: 100%;
      height: 9rem;
      margin: 0 0 1rem 0 !important;
    }
  }

  section { padding: 3rem 0;}
  .topics-wrapper .section-content {
    h1, h2, h3 {
      margin-bottom: 1rem;
    }
  }
  #hero .captions p {
    font-size: 2rem;
  }

  #short-intro {
    font-size: 2rem;
    line-height: 1.5;

    .section-content { padding: 0 1rem; }
  }

  #choose-place {
    height: 0;
  }

  #about {
    display: block;

    .slide {
      width: 100vw;
      height: unset !important;
    }

    .title { font-size: 2em; }

    .wrapper { width: 100%; }

    #about-cover .wrapper { 
      flex-direction: column;
      .logo { 
        width: 50vw;
        margin: 2rem 0;
      }
      .cover-text { width: 90vw; }
    }

    .slide__header {
      min-height: unset;
    }

    &-process {
      overflow: scroll;

      .step {
        width: 80vw;
        flex: 0 0 80vw;
        margin: 0 1rem;

        &:nth-child(2n) {
          transform: translateY(0);
        }
      }
    }

    &-howWeDoIt {
      .combine { 
        flex-direction: column;
        align-items: center;

        .tech {
          margin: -3rem 0 0 0;
        }
      }
    }

    &-help {
      .startingpoints {
        position: relative;
        top: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        &__item {
          margin-bottom: 2rem;
          position: relative;
          top: unset !important;
          left: unset !important;
          right: unset !important;
          bottom: unset !important;

          .intro span,
          .description {
            opacity: 1;
            transform: translateY(0);
          }
        }
      }
    }

    &-covering {
      .slide__body{
        position: relative;
        flex-direction: column;
      }

      svg {
        width: 100%;
        height: auto;
      }
    }
  }

  #contact {
    .contact__options { width: 100%; }
    .topic-links {
      &__wrapper {
        flex-direction: column;
      }

      &__item {
        flex: 1 0 auto;
        margin-bottom: 1rem;
        a{
          display: flex;
          align-items: center;
        }
        img { 
          flex: 0 1 3em; 
          object-fit: cover;
          height: 3em;
          margin: 0 1rem 0 0;
        }
      }
    }
  }

  .buttons {
    flex-direction: column;

    .button {
      margin: 0 0 1rem;
      text-align: center;
    }
  }

  .scroll-indicator {
    display: none;
  }
}

@media (min-width: 2100px){
  #about #about-howWeDoIt .combine{
    .business, .tech { width: 600px; height: 600px;}
  }
}

@keyframes bounce-scroll {
  from { transform: translateY(-20%);}
  to { transform: translateY(0);}
}