:root {
  --primary: #86bc25;
  --button: #26890D;
  --dark-green: #2C5234;
  --darkest-green: #1D2411;
  --background: rgb(15, 15, 12);
  --light: rgb(255, 255, 255);
  --light-gray: rgba(255, 255, 255, .6);
  --gray: #CBCBCB;
}

@font-face {
  font-family: "Open Sans";
  src: url("OpenSans-Regular.c602053a.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "Open Sans";
  src: url("OpenSans-Bold.a2904263.ttf");
  font-weight: 700;
}

@font-face {
  font-family: "Open Sans";
  src: url("OpenSans-Light.0f27ac48.ttf");
  font-weight: 300;
}

* {
  box-sizing: border-box;
}

html {
  height: 100vh;
  overflow-x: hidden;
  scroll-padding-top: 70px;
  font-size: 18px;
  scroll-behavior: smooth;
}

body {
  background: var(--background);
  color: #fff;
  padding: 0;
  margin: 0;
  font-family: "Open Sans", sans-serif;
  line-height: 1.35;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--primary);
}

h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child {
  margin-top: 0;
}

h1 {
  font-size: 2.2em;
}

h2 {
  font-size: 1.66em;
}

a {
  color: inherit;
  text-decoration: inherit;
}

section {
  padding: 6rem 0;
  position: relative;
}

input {
  padding: 0.5em;
  font-size: 1rem;
}

img {
  display: block;
  max-width: 100%;
}

button,
.button {
  font-size: 0.77em;
  background-color: var(--button);
  display: inline-block;
  padding: 0.7em 2em;
  transition: 0.2s;
}

button:hover,
.button:hover {
  background-color: var(--primary);
  color: var(--background);
}

.buttons {
  display: flex;
  margin-top: 4rem;
}

.buttons .button {
  display: block;
  margin-right: 1em;
}

p {
  font-size: 1em;
}

.block-with-image {
  display: flex;
  margin: 0 0 6rem;
}

.block-with-image img {
  flex: 0 0 3rem;
  object-fit: cover;
  width: 4rem;
  background-color: #aaa;
}

.block-with-image img:first-child {
  margin-right: 2.33rem;
}

.block-with-image img:last-child {
  margin-left: 2.33rem;
}

.block-with-image h3 {
  color: inherit;
  font-size: 1.33em;
  font-weight: 400;
}

.block-with-image .content {
  padding: 0.5em 0;
}

.block-with-image *:first-child {
  margin-top: 0;
}

.block-with-image *:last-child {
  margin-bottom: 0;
}

.block-with-image:last-of-type {
  margin-bottom: 3rem;
}

#nav-wheel {
  visibility: hidden;
  position: fixed;
  top: 50%;
  transform: translate(-60%, -50%);
}

#nav-wheel .wheel {
  width: 21.6vw;
  height: 21.6vw;
}

#nav-wheel .wheel img {
  width: 100%;
  height: 100%;
  display: block;
}

#nav-wheel ul {
  list-style: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0;
  margin: 0;
}

#nav-wheel ul li {
  position: absolute;
  display: block;
  white-space: nowrap;
}

#nav-wheel ul li a {
  font-size: 1.2em;
  color: var(--light-gray);
  transition: color 0.3s;
}

#nav-wheel ul li a strong {
  transition: inherit;
}

#nav-wheel ul li a span {
  transition: scale 0.5s, opacity 0.5s;
  display: inline-block;
}

#nav-wheel ul li a:hover, #nav-wheel ul li a.active {
  color: var(--light);
}

#nav-wheel ul li a:hover strong, #nav-wheel ul li a.active strong {
  color: var(--primary);
}

#nav-wheel ul li.hide span {
  transform: scaleX(0);
  opacity: 0;
  width: 0;
}

.scroll-indicator {
  display: block;
  position: fixed;
  bottom: 3rem;
  width: 100%;
  left: 0;
  right: 0;
  text-align: center;
}

.scroll-indicator p {
  margin-bottom: 1em !important;
}

.scroll-indicator img {
  margin: auto;
  animation: 0.7s bounce-scroll infinite alternate ease-in;
}

header {
  position: fixed;
  background-color: #000;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;
}

header .header-container {
  width: 90vw;
  max-width: 1200px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  height: 70px;
  align-items: center;
}

header .logo img {
  width: 130px;
  margin-right: 20px;
  display: inline-block;
}

header .logo span {
  border-left: 1px solid var(--light-gray);
  padding-left: 20px;
  display: inline-block;
  color: var(--primary);
  font-size: 1.66em;
  font-weight: 700;
}

header nav ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
}

header nav ul li {
  display: block;
  margin-left: 2em;
}

#hero {
  min-height: 400vh;
  display: flex;
  align-items: flex-start;
}

#hero h1 {
  font-weight: 400;
  color: var(--light);
}

#hero h1 strong {
  font-size: 5rem;
}

#hero .captions {
  position: fixed;
  top: 50%;
  transform: translateY(-10vh);
  text-align: center;
  width: 100%;
  left: 0;
  right: 0;
}

#hero .captions p {
  position: absolute;
  top: 0;
  opacity: 0;
  font-size: 10vh;
  font-weight: 600;
  margin: 0;
  line-height: 1;
  width: 100%;
  left: 0;
  right: 0;
}

#hero .canvas-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-height: 100vh;
  pointer-events: none;
}

#hero canvas {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#hero canvas.hidden {
  display: none;
}

#hero .section-content {
  font-size: 1.1em;
  text-align: center;
  margin: 40vh auto 0;
  max-width: 35em;
  transform: translateY(-50%);
}

.topics-wrapper {
  padding: 0;
}

.topics-wrapper section {
  margin-left: calc(12vw + 13em);
}

.topics-wrapper .section-content {
  position: relative;
  margin: auto;
  max-width: 40em;
}

.topics-wrapper .section-content > h1,
.topics-wrapper .section-content > h2,
.topics-wrapper .section-content > h3 {
  margin: 0 0 3rem;
}

#short-intro {
  display: flex;
  min-height: 150vh;
  padding: 0;
  font-size: 3em;
  font-weight: 800;
  line-height: 2;
  letter-spacing: 0.01em;
}

#short-intro .section-content {
  position: absolute;
  opacity: 0;
  transition: opacity 0.3s;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

#short-intro p {
  width: fit-content;
  margin: auto;
}

#short-intro .shown {
  position: fixed;
  opacity: 1;
}

#short-intro span {
  opacity: 0.3;
  transition: opacity 0.1s;
}

#short-intro span.highlight {
  opacity: 1;
}

#short-intro strong {
  font-weight: 700;
}

#choose-place {
  text-align: center;
  margin-left: 0;
  z-index: 1;
  pointer-events: none;
  height: 120vh;
}

#choose-place > div {
  font-size: 3em;
  font-weight: 800;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
}

.about-wrapper {
  width: 100vw;
}

#about {
  padding: 0;
  display: flex;
}

#about .slide {
  flex: 0 0 auto;
  height: calc(100vh - 70px);
  display: flex;
  margin: auto 0;
  padding: 5vh 0;
}

#about .slide__header {
  min-height: 12rem;
}

#about .slide__body {
  margin: auto 0;
}

#about .slide p {
  font-size: 1.33rem;
}

#about .wrapper {
  padding: 0 5vw;
  display: flex;
  flex-direction: column;
}

#about .title {
  line-height: 1;
  font-size: 3.5rem;
  margin-bottom: 0;
}

#about-cover {
  min-width: 100vw;
}

#about-cover .wrapper {
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

#about-cover .wrapper .logo {
  width: 320px;
  margin-right: 8rem;
}

#about-cover .wrapper .cover-text {
  width: 500px;
}

#about-description {
  width: 80vw;
}

#about-description .wrapper {
  width: 100%;
}

#about-description p {
  max-width: 30em;
  display: block;
}

#about-description .left-text,
#about-description .right-text {
  position: relative;
  width: 100%;
}

#about-description .right-text p {
  margin-left: auto;
}

#about-process {
  padding: 0;
  width: unset;
  display: flex;
  align-items: center;
}

#about-process .step {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 22rem;
  margin-right: 2em;
  background-color: var(--dark-green);
  transition: background-color 0.5s, color 0.5s;
}

#about-process .step h3 {
  font-size: 2em;
  margin-top: 0;
}

#about-process .step ul {
  padding: 0 0 0 1em;
  margin: 0;
}

#about-process .step .text {
  z-index: 1;
  padding: 2.5em 2.5em 1em;
  margin: auto 0;
}

#about-process .step .text p:first-child {
  margin: 0;
}

#about-process .step figure {
  text-align: center;
  display: block;
  margin: 0 0 1em;
  width: 100%;
  padding: 0 1em;
  z-index: 1;
}

#about-process .step figcaption {
  color: var(--gray);
}

#about-process .step img {
  width: 100%;
  margin: 0 auto;
}

#about-process .step .background {
  z-index: 0;
}

#about-process .step .background img {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: auto;
}

#about-process .step .background span {
  color: #00000033;
  position: absolute;
  right: 0;
  top: 0;
  overflow: hidden;
  font-size: 8rem;
  font-weight: 800;
  line-height: 0.8;
}

#about-process .step:nth-child(even) {
  transform: translateY(-100px);
}

#about-process .step.active {
  background-color: var(--light);
  color: var(--background);
}

#about-process .step.active figcaption {
  color: #707070;
}

#about-whyCampfire p {
  max-width: 30em;
  margin-bottom: 2em;
  transform: translate(0);
}

#about-randomToOrchestrated .wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#about-howWeDoIt {
  min-width: 60vw;
}

#about-howWeDoIt .combine {
  display: flex;
  justify-content: center;
}

#about-howWeDoIt .combine ul {
  position: relative;
  z-index: 2;
  list-style-type: none;
  padding: 0;
  line-height: 1.5;
  text-align: center;
  font-weight: 700;
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 0.2s, transform 0.2s;
  transition-delay: 0;
}

#about-howWeDoIt .combine .business,
#about-howWeDoIt .combine .tech {
  background-color: var(--darkest-green);
  width: 22rem;
  height: 22rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  mix-blend-mode: screen;
}

#about-howWeDoIt .combine .tech {
  margin-left: -3em;
}

#about-howWeDoIt .combine img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: 1;
  transition: opacity 0.6s;
}

#about-howWeDoIt .combine.active img, #about-howWeDoIt .combine:hover img {
  opacity: 0.33;
}

#about-howWeDoIt .combine.active ul, #about-howWeDoIt .combine:hover ul {
  transform: translateY(0);
  opacity: 1;
  transition-delay: 0.4s;
}

#about-help {
  width: 90vw;
  position: relative;
}

#about-help .slide__header {
  z-index: 1;
  pointer-events: none;
}

#about-help .startingpoints {
  position: absolute;
  right: 5vw;
  top: 0;
  bottom: 0;
  width: max(60vw, 700px);
  font-size: 1rem;
}

#about-help .startingpoints::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  opacity: 0.2;
  background-image: url("nodeBall.8c94690a.jpg");
  background-color: var(--background);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  background-blend-mode: lighten;
}

#about-help .startingpoints__item {
  position: absolute;
  transition: opacity 0.3s;
  width: 18rem;
  text-align: center;
  cursor: pointer;
}

#about-help .startingpoints__item .intro {
  font-weight: 700;
  margin-bottom: 1em;
}

#about-help .startingpoints__item .intro span {
  transform: translateY(-100%);
  opacity: 0;
  transition: 0.3s;
  display: block;
}

#about-help .startingpoints__item .description {
  transform: translateY(-100%);
  opacity: 0;
  transition: 0.3s;
}

#about-help .startingpoints__item p {
  font-size: inherit;
}

#about-help .startingpoints.has-shown-items .startingpoints__item {
  opacity: 0.2;
}

#about-help .startingpoints.has-shown-items .startingpoints__item.active {
  opacity: 1;
  cursor: default;
}

#about-help .startingpoints.has-shown-items .startingpoints__item.active .description {
  transform: translateY(0);
  opacity: 1;
}

#about-help .startingpoints.has-shown-items .startingpoints__item.active .intro span {
  transform: translateY(0);
  opacity: 1;
}

#about-help .startingpoints.has-shown-items .startingpoints__item.active .circle {
  box-shadow: 0 0 15px 2px var(--primary);
}

#about-help .startingpoints #monetize {
  top: 20%;
  left: 30%;
}

#about-help .startingpoints #distribute {
  top: 20%;
  left: unset;
  right: 5%;
}

#about-help .startingpoints #connect {
  top: 40%;
  left: 45%;
}

#about-help .startingpoints #data-catalog {
  top: unset;
  bottom: 5%;
  left: 30%;
}

#about-help .startingpoints #transparency {
  top: unset;
  bottom: 20%;
  left: unset;
  right: 0;
}

#about-help .circle {
  background-color: var(--light);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.5em;
  height: 3.5em;
  border-radius: 50%;
  margin: 0 auto 0.5rem;
  transition: 0.3s;
  padding: 0.7em;
}

#about-help .circle img {
  display: block;
  max-height: 100%;
  margin: auto;
}

#about-useCases {
  background-color: var(--light);
  color: var(--background);
  display: grid;
  grid-template-columns: repeat(4, minmax(min-content, 20vw));
  grid-template-rows: min-content 2em auto;
  padding: 0;
}

#about-useCases .usecases__head {
  grid-column: 1/span 4;
  padding: 2em;
}

#about-useCases .usecases__inside,
#about-useCases .usecases__outside {
  grid-row: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}

#about-useCases .usecases__inside {
  background-color: #2C523466;
}

#about-useCases .usecases__outside {
  background-color: #86BC2566;
  grid-column: 2/span 3;
}

#about-useCases .usecases__card {
  grid-row: 3;
  padding: 1em 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

#about-useCases .usecases__card h3 {
  min-height: 3em;
  color: var(--background);
  margin: 0 0 1.5rem;
}

#about-useCases .usecases__card img {
  height: 100px;
  margin-bottom: 1.5rem;
}

#about-useCases .usecases__card .next-level {
  margin-top: auto;
  padding: 2em 0;
}

#about-covering {
  width: 100vw;
}

#about-covering .slide__header {
  width: 100%;
  max-width: 35em;
}

#about-covering .slide__body {
  position: absolute;
  top: 0;
  left: 5vw;
  bottom: 0;
  right: 0;
  align-items: center;
  display: flex;
}

#about-covering .slide__body .descriptions {
  flex: 0 1 auto;
  overflow: hidden;
}

#about-covering .slide__body .descriptions > div {
  transition: transform 0.3s;
  max-height: 0;
  max-width: 22rem;
  overflow: hidden;
  transform: translateX(-100%);
}

#about-covering .slide__body .descriptions > div h3 {
  font-size: 1em;
  border-bottom: 1px solid currentColor;
}

#about-covering svg {
  flex: 0 1 auto;
  display: block;
  height: 70vh;
  width: auto;
  margin: auto;
}

#about-covering svg circle {
  transition: fill 0.1s;
}

#about-covering svg #circle-software-solution {
  fill: #575757;
}

#about-covering svg #circle-software-solution:hover, #about-covering svg #circle-software-solution.active {
  fill: #86BC25;
}

#about-covering svg #circle-product-poc {
  fill: #484848;
}

#about-covering svg #circle-product-poc:hover, #about-covering svg #circle-product-poc.active {
  fill: #63AC41;
}

#about-covering svg #circle-product-mvp {
  fill: #383838;
}

#about-covering svg #circle-product-mvp:hover, #about-covering svg #circle-product-mvp.active {
  fill: #468629;
}

#about-covering svg #circle-sellable {
  fill: #272727;
}

#about-covering svg #circle-sellable:hover, #about-covering svg #circle-sellable.active {
  fill: #2D683D;
}

#about-covering svg #circle-marketable {
  fill: #141414;
}

#about-covering svg #circle-marketable:hover, #about-covering svg #circle-marketable.active {
  fill: #3A818E;
}

#contact {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

#contact .section-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: flex-start;
  padding: 0 10vw;
}

#contact .contact__options {
  margin: auto 0;
  width: 50%;
}

#contact .topic-links__wrapper {
  display: flex;
}

#contact .topic-links__item {
  display: flex;
  flex-direction: column;
  margin-right: 2em;
  flex: 0 1 300px;
}

#contact .topic-links__item img {
  display: block;
  width: 100%;
  margin-bottom: 1rem;
}

#contact .topic-links__item a {
  font-weight: 700;
  transition: color 0.3s;
}

#contact .topic-links__item:hover a {
  color: var(--primary);
}

footer {
  height: 3em;
}

footer ul {
  display: flex;
  list-style-type: none;
  justify-content: center;
  align-items: center;
}

footer ul li {
  margin: 0 1em;
}

@media screen and (max-width: 1440px), screen and (max-height: 800px) {
  html {
    font-size: 14px;
  }

  #about .title {
    font-size: 2.5em;
  }
  #about-process .step {
    width: 18rem;
  }
  #about-process .step .text {
    padding: 1.5em 2em;
  }
  #about-help .startingpoints {
    top: 12rem;
  }
  #about-help .startingpoints #monetize {
    top: 15%;
    left: 20%;
  }
  #about-help .startingpoints #distribute {
    top: 15%;
    left: unset;
    right: 10%;
  }
  #about-help .startingpoints #connect {
    top: 30%;
    left: 40%;
  }
  #about-help .startingpoints #data-catalog {
    top: unset;
    bottom: 5%;
    left: 30%;
  }
  #about-help .startingpoints #transparency {
    top: unset;
    bottom: 10%;
    left: unset;
    right: 5%;
  }
}

@media (max-width: 1024px) {
  #nav-wheel {
    display: none;
  }

  .topics-wrapper section {
    margin: 0 auto;
  }
  .topics-wrapper .section-content {
    width: 90vw;
  }
}

@media (max-width: 767px) {
  .block-with-image {
    flex-direction: column;
    margin-bottom: 3rem;
  }
  .block-with-image img {
    order: -1;
    flex: 0 1 5rem;
    width: 100%;
    height: 9rem;
    margin: 0 0 1rem 0 !important;
  }

  section {
    padding: 3rem 0;
  }

  .topics-wrapper .section-content h1, .topics-wrapper .section-content h2, .topics-wrapper .section-content h3 {
    margin-bottom: 1rem;
  }

  #hero .captions p {
    font-size: 2rem;
  }

  #short-intro {
    font-size: 2rem;
    line-height: 1.5;
  }
  #short-intro .section-content {
    padding: 0 1rem;
  }

  #choose-place {
    height: 0;
  }

  #about {
    display: block;
  }
  #about .slide {
    width: 100vw;
    height: unset !important;
  }
  #about .title {
    font-size: 2em;
  }
  #about .wrapper {
    width: 100%;
  }
  #about #about-cover .wrapper {
    flex-direction: column;
  }
  #about #about-cover .wrapper .logo {
    width: 50vw;
    margin: 2rem 0;
  }
  #about #about-cover .wrapper .cover-text {
    width: 90vw;
  }
  #about .slide__header {
    min-height: unset;
  }
  #about-process {
    overflow: scroll;
  }
  #about-process .step {
    width: 80vw;
    flex: 0 0 80vw;
    margin: 0 1rem;
  }
  #about-process .step:nth-child(2n) {
    transform: translateY(0);
  }
  #about-howWeDoIt .combine {
    flex-direction: column;
    align-items: center;
  }
  #about-howWeDoIt .combine .tech {
    margin: -3rem 0 0 0;
  }
  #about-help .startingpoints {
    position: relative;
    top: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  #about-help .startingpoints__item {
    margin-bottom: 2rem;
    position: relative;
    top: unset !important;
    left: unset !important;
    right: unset !important;
    bottom: unset !important;
  }
  #about-help .startingpoints__item .intro span,
#about-help .startingpoints__item .description {
    opacity: 1;
    transform: translateY(0);
  }
  #about-covering .slide__body {
    position: relative;
    flex-direction: column;
  }
  #about-covering svg {
    width: 100%;
    height: auto;
  }

  #contact .contact__options {
    width: 100%;
  }
  #contact .topic-links__wrapper {
    flex-direction: column;
  }
  #contact .topic-links__item {
    flex: 1 0 auto;
    margin-bottom: 1rem;
  }
  #contact .topic-links__item a {
    display: flex;
    align-items: center;
  }
  #contact .topic-links__item img {
    flex: 0 1 3em;
    object-fit: cover;
    height: 3em;
    margin: 0 1rem 0 0;
  }

  .buttons {
    flex-direction: column;
  }
  .buttons .button {
    margin: 0 0 1rem;
    text-align: center;
  }

  .scroll-indicator {
    display: none;
  }
}

@media (min-width: 2100px) {
  #about #about-howWeDoIt .combine .business, #about #about-howWeDoIt .combine .tech {
    width: 600px;
    height: 600px;
  }
}

@keyframes bounce-scroll {
  from {
    transform: translateY(-20%);
  }
  to {
    transform: translateY(0);
  }
}
/*# sourceMappingURL=index.975b2aa7.css.map */
